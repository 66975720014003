<template>
  <!-- <BHeader class="hidden lg:block" /> -->
  <div>
    <div class="lg:hidden">
      <div class="px-6 lg:min-h-screen py-2 lg:pt-24 flex flex-col font-semibold">
    <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
      <button @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Security</h2>
    </header>
    <ul>
      <li class="my-6 py-1 flex items-center">
        <Lock2Icon
          class="mx-auto h-5 stroke-2 stroke-current"
          style="stroke: #577387"
        />
        <router-link
          class="flex-grow text-text-alternate-1 pl-3.5"
          :to="{ name: 'SecurityQuestions' }"
          >Security Question</router-link
        >
        <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
      </li>
      <li class="my-6 py-1 flex items-center">
        <Lock2Icon
          class="mx-auto h-5 stroke-2 stroke-current"
          style="stroke: #577387"
        />
        <router-link
          class="flex-grow text-text-alternate-1 pl-3.5"
          :to="{ name: 'UpdatePassword' }"
          >Change Password</router-link
        >
        <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
      </li>
    </ul>
  </div>
    </div>

    <div class="hidden lg:flex justify-center min-h-screen pt-4 pb-24">
      <div class="b-card mt-24 pt-8 mb-24 ">
        <div class="px-26 flex flex-col  font-semibold">
    <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
      <button @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Security</h2>
    </header>
    <ul>
      <li class="my-6 py-1 flex items-center">
        <Lock2Icon
          class="mx-auto h-5 stroke-2 stroke-current"
          style="stroke: #577387"
        />
        <router-link
          class="flex-grow text-text-alternate-1 pl-3.5"
          :to="{ name: 'SecurityQuestions' }"
          >Security Question</router-link
        >
        <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
      </li>
      <li class="my-6 py-1 flex items-center">
        <Lock2Icon
          class="mx-auto h-5 stroke-2 stroke-current"
          style="stroke: #577387"
        />
        <router-link
          class="flex-grow text-text-alternate-1 pl-3.5"
          :to="{ name: 'UpdatePassword' }"
          >Change Password</router-link
        >
        <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
      </li>
    </ul>
  </div>
      </div>
    </div>
  </div>
  <!-- <BFooterLG class="hidden bottom-0 mt-36 absolute lg:block" /> -->
</template>

<script>
// import BHeader from '../../../components/ui/BHeader.vue';
// import BFooterLG from '../../../components/ui/BFooterLG.vue';
export default {
  name: 'Security',
  setup() {},
  // components: { BHeader, BFooterLG },
};
</script>
